import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react';

function Gallery() {

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
        });
        
        function openLightboxOnSlide(number) {
        setLightboxController({
        toggler: !lightboxController.toggler,
        slide: number
        });
        }

return (
<>




<div>

<img src='./img/galleria/min/min_31.jpg' onClick={() => openLightboxOnSlide(1) } className="FotoGall1" source='foto1' alt='Photogall'>
</img>

<img src='./img/galleria/min/min_30.jpg' onClick={() => openLightboxOnSlide(2) } className="FotoGall1" alt='Photogall'>
</img>

<img src='./img/galleria/min/min_29.jpg' onClick={() => openLightboxOnSlide(3) } className="FotoGall1" alt='Photogall'>
</img>

</div>


<div>

<img src='./img/galleria/min/min_28.jpg' onClick={() => openLightboxOnSlide(4) } className="FotoGall1" source='foto1' alt='Photogall'>
</img>

<img src='./img/galleria/min/min_27.jpg' onClick={() => openLightboxOnSlide(5) } className="FotoGall1" alt='Photogall'>
</img>

<img src='./img/galleria/min/min_26.jpg' onClick={() => openLightboxOnSlide(6) } className="FotoGall1" alt='Photogall'>
</img>

</div>


<div>

<img src='./img/galleria/min/min_25.jpg' onClick={() => openLightboxOnSlide(7) } className="FotoGall1" source='foto1' alt='Photogall'>
</img>

<img src='./img/galleria/min/min_24.jpg' onClick={() => openLightboxOnSlide(8) } className="FotoGall1" alt='Photogall'>
</img>

<img src='./img/galleria/min/min_23.jpg' onClick={() => openLightboxOnSlide(9) } className="FotoGall1" alt='Photogall'>
</img>

</div>




<div>

<img src='./img/galleria/min/min_21.jpg' onClick={() => openLightboxOnSlide(10) } className="FotoGall1" source='foto1' alt='Photogall'>
</img>

<img src='./img/galleria/min/min_11.jpg' onClick={() => openLightboxOnSlide(11) } className="FotoGall1" alt='Photogall'>
</img>

<img src='./img/galleria/min/min_20.jpg' onClick={() => openLightboxOnSlide(12) } className="FotoGall1" alt='Photogall'>
</img>

</div>




<div>

<img src='./img/galleria/min/min_19.jpg' onClick={() => openLightboxOnSlide(13) } className="FotoGall1" source='foto1' alt='Photogall'>
</img>

<img src='./img/galleria/min/min_18.jpg' onClick={() => openLightboxOnSlide(14) } className="FotoGall1" alt='Photogall'>
</img>

<img src='./img/galleria/min/min_17.jpg' onClick={() => openLightboxOnSlide(15) } className="FotoGall1" alt='Photogall'>
</img>

</div>








<div>

<img src='./img/galleria/min/min_4.jpg' onClick={() => openLightboxOnSlide(16) } className="FotoGall1" source='foto1' alt='Photogall'>
</img>

<img src='./img/galleria/min/min_5.jpg' onClick={() => openLightboxOnSlide(17) } className="FotoGall1" alt='Photogall'>
</img>

<img src='./img/galleria/min/min_16.jpg' onClick={() => openLightboxOnSlide(18) } className="FotoGall1" alt='Photogall'>
</img>

</div>

<div>
<img src='./img/galleria/min/min_1.jpg' onClick={() => openLightboxOnSlide(19) } className="FotoGall1" source='foto1' alt='Photogall'>
</img>

<img src='./img/galleria/min/min_2.jpg' onClick={() => openLightboxOnSlide(20) } className="FotoGall1" alt='Photogall'>
</img>

<img src='./img/galleria/min/min_3.jpg' onClick={() => openLightboxOnSlide(21) } className="FotoGall1" alt='Photogall'>
</img>

</div>



<div>
<img src='./img/galleria/min/min_6.jpg' onClick={() => openLightboxOnSlide(22) } className="FotoGall1" source='foto1' alt='Photogall'>
</img>

<img src='./img/galleria/min/min_7.jpg' onClick={() => openLightboxOnSlide(23) } className="FotoGall1" alt='Photogall'>
</img>

<img src='./img/galleria/min/min_8.jpg' onClick={() => openLightboxOnSlide(24) } className="FotoGall1" alt='Photogall'>
</img>

</div>


<div>
<img src='./img/galleria/min/min_9.jpg' onClick={() => openLightboxOnSlide(25) } className="FotoGall1" source='foto1' alt='Photogall'>
</img>

<img src='./img/galleria/min/min_10.jpg' onClick={() => openLightboxOnSlide(26) } className="FotoGall1" alt='Photogall'>
</img>

<img src='./img/galleria/min/min_12.jpg' onClick={() => openLightboxOnSlide(27) } className="FotoGall1" alt='Photogall'>
</img>

</div>

<div>
<img src='./img/galleria/min/min_13.jpg' onClick={() => openLightboxOnSlide(28) } className="FotoGall1" source='foto1' alt='Photogall'>
</img>

<img src='./img/galleria/min/min_14.jpg' onClick={() => openLightboxOnSlide(29) } className="FotoGall1" alt='Photogall'>
</img>

<img src='./img/galleria/min/min_15.jpg' onClick={() => openLightboxOnSlide(30) } className="FotoGall1" alt='Photogall'>
</img>

</div> <br/> <br/>


<FsLightbox
toggler={lightboxController.toggler}
type="image"

sources={[
     'img/galleria/gall31.jpg',
    'img/galleria/gall30.jpg',
    'img/galleria/gall29.jpg',
    'img/galleria/gall28.jpg',
    'img/galleria/gall27.jpg',


    'img/galleria/gall26.jpg',
    'img/galleria/gall25.jpg',
    'img/galleria/gall24.jpg',
    'img/galleria/gall23.jpg',
    'img/galleria/gall21.jpg', 

    'img/galleria/gall11.jpg',
    'img/galleria/gall20.jpg',
    'img/galleria/gall19.jpg',
    'img/galleria/gall18.jpg',
    'img/galleria/gall17.jpg',

'img/galleria/gall4.jpg', 
'img/galleria/gall5.jpg',
'img/galleria/gall16.jpg',
'img/galleria/gall1.jpg',
'img/galleria/gall2.jpg',

'img/galleria/gall3.jpg',
'img/galleria/gall6.jpg',
'img/galleria/gall7.jpg',
'img/galleria/gall8.jpg',
'img/galleria/gall9.jpg',

'img/galleria/gall10.jpg',
'img/galleria/gall12.jpg',
'img/galleria/gall13.jpg',
'img/galleria/gall14.jpg',
'img/galleria/gall15.jpg',
]}
slide={lightboxController.slide}
/>
</>
);
}

export default Gallery;