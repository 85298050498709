import React from 'react'

function NotFound() {
  return (
    <div>
        <h1>
            Pagina non trovata torna indietro
        </h1>
    </div>
  )
}

export default NotFound